<script setup>
import { ref, watch } from 'vue' 
import Layout from "../../layouts/main.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Swal from "sweetalert2";
import store from "@/state/store";

import i18n from "../../i18n";

const { t } = i18n.global;

const user = ref(store.getters['userAuth/getUserData'])

const faturaClose = ref(null)
const adresClose = ref(null)
const faturaAddClose = ref(null)
const adresAddClose = ref(null)

const FaturaAdresleri = store.getters['user/getFaturaAdresleri']
const SevkiyatAdresleri = store.getters['user/getSevkiyatAdresleri']

const selectAdres = ref(null)
const selectAdresData = ref(null)

const selectFatura = ref(null)
const selectFaturaData = ref(null)

const selectAddFatura = ref(null)
const selectAddFaturaData = ref(null)

const selectAddAdres = ref(null)
const selectAddAdresData = ref(null)

const deviceType = ref([
    "ri-macbook-line",
    "ri-macbook-line",
    "ri-smartphone-line",
    "ri-tablet-line"
])

const GirisGecmisi = ref([
  {
    'deviceID' : 1,
    'deviceType' : 1,
    'deviceName' : 'Apple Mac',
    'deviceBrand' : 'Apple',
    'deviceModel' : 'Mac',
    'deviceFamily' : 'Mac',
    'browser' : 'Chrome',
    'browserVersion' : '101.0.0',
    'os' : 'Mac OS X',
    'osVersion' : '10.15.7',
    'ipAddress' : '127.0.0.1',
    'ipCountry' : 'Türkiye',
    'ipCity' : 'Yalova',
    'createDate' : '2022-10-17 16:47:41'
  },
  {
    'deviceID' : 2,
    'deviceType' : 1,
    'deviceName' : 'Apple Mac',
    'deviceBrand' : 'Apple',
    'deviceModel' : 'Mac',
    'deviceFamily' : 'Mac',
    'browser' : 'Chrome',
    'browserVersion' : '101.0.0',
    'os' : 'Mac OS X',
    'osVersion' : '10.15.7',
    'ipAddress' : '127.0.0.1',
    'ipCountry' : 'Türkiye',
    'ipCity' : 'Yalova',
    'createDate' : '2022-10-17 16:47:41'
  }
])

watch(selectAdres, (value) => {
      if (value != null) {
        selectAdresData.value = JSON.parse(JSON.stringify(SevkiyatAdresleri.find((adres) => adres.id==selectAdres.value)))
      } else {
        selectAdresData.value = null
      } 
})

watch(selectFatura, (value) => {
      if (value != null) {
        selectFaturaData.value = JSON.parse(JSON.stringify(FaturaAdresleri.find((fatura) => fatura.id==selectFatura.value)))
      } else {
        selectFaturaData.value = null
      } 
})

watch(selectAddFatura, (value) => {
  console.log(selectAddFatura.value)
  if( value != null){
    selectAddFaturaData.value  = 
    {
      'id' : FaturaAdresleri.length + 1,
      'baslik' : '',
      'unvan' : '',
      'vergidairesi' : '',
      'vergino' :  '',
      'il' : '',
      'ilce' : '',
      'bucak' : '',
      'mahalle' : '',
      'adres' : ''
    }
  } else {
    selectAddFaturaData.value = null
  }
})

watch(selectAddAdres, (value) => {
  console.log(selectAddAdres.value)
  if( value != null){
    selectAddAdresData.value  = 
    {
      'id' : SevkiyatAdresleri.length+1,
      'baslik' : '',
      'il' : '',
      'ilce' : '',
      'bucak' : '',
      'mahalle' : '',
      'adres' : ''
    }
  } else {
    selectAddAdresData.value = null
  }
})

function updateAdress(){
  let saNumber = SevkiyatAdresleri.findIndex(adres => adres.id == selectAdres.value)
  SevkiyatAdresleri[saNumber] = selectAdresData.value
  store.dispatch(
        "user/updateFaturaAdresi",
        { index: saNumber, data : selectAdresData.value }
  )
  adresClose.value.click()
  selectAdres.value = null
}

function updateFatura(){
  let fNumber = FaturaAdresleri.findIndex(fatura => fatura.id == selectFatura.value)
  FaturaAdresleri[fNumber] = selectFaturaData.value
  store.dispatch(
        "user/updateFaturaAdresi",
        { index: fNumber, data : selectFaturaData.value }
  )
  faturaClose.value.click()
  selectFatura.value = null
}

function removeFatura(id){
  Swal.fire({
    title: t("Fatura Bilgileri Silinsin Mi?"),
    html: t("Fatura bilgilerinin silinmesini istiyor musunuz?"),
    icon: "info",
    confirmButtonText: t("Evet, sil"),
    cancelButtonText: t("Vazgeç"),
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      let fNumber = FaturaAdresleri.findIndex(fatura => fatura.id == id)
      // FaturaAdresleri.splice(fNumber, 1)
      store.dispatch('user/removeFaturaAdresi', {index: fNumber})
    }
  });
}

function removeAdres(id){
  Swal.fire({
    title: t("Sevkiyat Adres Bilgisi Silinsin Mi?"),
    html: t("Sevkiyat Adres bilgilerinin silinmesini istiyor musunuz?"),
    icon: "info",
    confirmButtonText: t("Evet, sil"),
    cancelButtonText: t("Vazgeç"),
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      let fNumber = SevkiyatAdresleri.findIndex(adres => adres.id == id)
      // SevkiyatAdresleri.splice(fNumber, 1)
      store.dispatch('user/removeSevkiyatAdresi', {index: fNumber})
    }
  });
}

function addFatura(){
  // FaturaAdresleri.push(selectAddFaturaData.value)
  // SevkiyatAdresleri.push({
  //   'id' : SevkiyatAdresleri.length+1,
  //   'baslik' : selectAddFaturaData.value.baslik,
  //   'il' : selectAddFaturaData.value.il,
  //   'ilce' : selectAddFaturaData.value.ilce,
  //   'bucak' : selectAddFaturaData.value.bucak,
  //   'mahalle' : selectAddFaturaData.value.mahalle,
  //   'adres' : selectAddFaturaData.value.adres
  // })
  store.dispatch("user/addFaturaAdresi", { data: selectAddFaturaData.value });
  store.dispatch("user/addSevkiyatAdresi", { data: {
        'id' : SevkiyatAdresleri.length+1,
        'baslik' : selectAddFaturaData.value.baslik,
        'il' : selectAddFaturaData.value.il,
        'ilce' : selectAddFaturaData.value.ilce,
        'bucak' : selectAddFaturaData.value.bucak,
        'mahalle' : selectAddFaturaData.value.mahalle,
        'adres' : selectAddFaturaData.value.adres
      }
    });
  faturaAddClose.value.click()
  selectAddFatura.value = null
}
function addAdres(){
  // SevkiyatAdresleri.push(selectAddAdresData.value)
  store.dispatch("user/addSevkiyatAdresi", { data: selectAddAdresData.value });
  adresAddClose.value.click()
  selectAddAdres.value = null
}
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img src="@/assets/images/profile-bg.jpg" class="profile-wid-img" alt="" />
        <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit">
              <input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input" />
              <label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light">
                <i class="ri-image-edit-line align-bottom me-1"></i> Change
                Cover
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                ">
                <img src="@/assets/images/users/avatar-3.jpg" class="
                    rounded-circle
                    avatar-xl
                    img-thumbnail
                    user-profile-image
                  " alt="user-profile-image" />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input id="profile-img-file-input" type="file" class="profile-img-file-input" />
                  <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                    <span class="avatar-title rounded-circle bg-light text-body">
                      <i class="ri-camera-fill"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="mb-1">{{ user.firstName }} {{ user.lastName }}</h5>
              <p class="text-muted mb-0">{{ user.firma }}</p>
            </div>
          </div>
        </div>
        <!--end card-->
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">Portfolio</h5>
              </div>
              <div class="flex-shrink-0">
                <a href="javascript:void(0);" class="badge bg-light text-primary fs-12"><i
                    class="ri-add-fill align-bottom me-1"></i> Add</a>
              </div>
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-dark text-light">
                  <i class="ri-mail-fill"></i>
                </span>
              </div>
              <input type="email" class="form-control" id="ePosta" placeholder="EPostaAdresi" value="ozberk@elektromarketim.com" />
            </div>
            <div class="mb-3 d-flex">
              <div class="avatar-xs d-block flex-shrink-0 me-3">
                <span class="avatar-title rounded-circle fs-16 bg-primary">
                  <i class="ri-global-fill"></i>
                </span>
              </div>
              <input type="text" class="form-control" id="websiteInput" placeholder="www.example.com"
                value="www.umelsan.com.tr" />
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                  <i class="fas fa-user"></i>
                  {{ $t("Kişisel Bilgiler") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                  <i class="far fa-lock"></i>
                  {{ $t("Parola Değiştir") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#faturaBilgileri" role="tab">
                  <i class="far fa-envelope"></i>
                  {{ $t("Fatura Bilgileri") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#adresBilgileri" role="tab">
                  <i class="far fa-home"></i>
                  {{ $t("Adres Bilgileri") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#privacy" role="tab">
                  <i class="far fa-envelope"></i>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label">{{ $t("Ad") }}</label>
                        <input type="text" class="form-control" id="firstnameInput" :placeholder="t('Adınızı giriniz')"
                          :value="user.firstName" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="lastnameInput" class="form-label">{{ $t("Soyad") }}</label>
                        <input type="text" class="form-control" id="lastnameInput" :placeholder="t('Soyadınızı giriniz')"
                          :value="user.lastName" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="phonenumberInput" class="form-label">{{ $t("Telefon Numarası") }}</label>
                        <input type="text" class="form-control" id="phonenumberInput"
                          :placeholder="t('Telefon Numaranızı Giriniz')" value="+(90) 500 000 00 00" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label">{{ $t("E-Posta Adresi") }}</label>
                        <input type="email" class="form-control" id="emailInput" :placeholder="t('E-Posta Adresinizi Giriniz')"
                          value="ozberk@elektromarketim.com" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          {{ $t("Güncelle") }}
                        </button>
                        <button type="button" class="btn btn-soft-success">
                          {{ $t("Vazgeç") }}
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label">{{ $t("Mevcut Parola") }}*</label>
                        <input type="password" class="form-control" id="oldpasswordInput"
                          :placeholder="t('Mevcut Parolanızı Giriniz')" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label">{{ $t("Yeni Parola") }}*</label>
                        <input type="password" class="form-control" id="newpasswordInput"
                          :placeholder="t('Yeni Parola Giriniz')" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label">{{ $t("Parolayı Onayla") }}*</label>
                        <input type="password" class="form-control" id="confirmpasswordInput"
                          :placeholder="t('Yeni Parolayı Onaylayın')" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <a href="javascript:void(0);" class="link-primary text-decoration-underline">{{ $t("Parolayı mı unuttunuz?") }}</a>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success">
                          {{ $t("Parola Değiştir") }}
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <div class="mt-4 mb-3 border-bottom pb-2">
                  <div class="float-end">
                    <a href="javascript:void(0);" class="link-primary">{{ $t("Tümünden Çıkış Yap") }}</a>
                  </div>
                  <h5 class="card-title">{{ $t("Giriş Geçmişi") }}</h5>
                </div>
                <div class="d-flex align-items-center mb-3" v-for="cihaz in GirisGecmisi" :key="cihaz.deviceID">
                  <div class="flex-shrink-0 avatar-sm">
                    <div class="avatar-title bg-light text-primary rounded-3 fs-18">
                      <i :class="deviceType[cihaz.deviceType]"></i>
                    </div>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h6 class="fs-15">{{ cihaz.deviceName }}</h6>
                    <p class="text-muted mb-0">
                      {{ cihaz.ipCity }}, {{ cihaz.ipCountry }} - {{ cihaz.createDate }}
                    </p>
                  </div>
                  <div>
                    <a href="javascript:void(0);">{{ $t("Çıkış Yap") }}</a>
                  </div>
                </div>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="faturaBilgileri" role="tabpanel">
                <div class="d-flex">
                  <div class="p-2 flex-grow-1"><h4>{{ $t("Fatura Bilgileri") }}</h4></div>
                  <div class="p-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#faturaAddModal"
                        @click="selectAddFatura = true"
                      >
                        {{ $t("Fatura Bilgisi Ekle") }}
                      </button>
                  </div>  
                </div>
                <form>
                  <div id="newlink">
                    <div id="1">
                      <div class="row">
                        <div class="row gy-2">
                      <div class="col-lg-4 col-sm-6" v-for="fatura in FaturaAdresleri" :key="fatura.id">
                        <div class="form-check card-radio">
                          <input
                            :id="'faturaAddress'+fatura.id"
                            name="faturaAddress"
                            type="radio"
                            class="form-check-input"
                            checked
                          />
                          <label
                            class="form-check-label"
                            :for="'faturaAddress'+fatura.id"
                          >
                            <span
                              class="
                                mb-4
                                fw-bold
                                d-block
                                text-muted text-uppercase
                              "
                              >{{ fatura.baslik }}</span
                            >

                            <span class="fs-15 mb-2">{{ fatura.unvan }}</span>
                            <span
                              class="
                                text-muted
                                fw-normal
                                text-wrap
                                mb-1
                                d-block
                              "
                              >{{ fatura.adres }} {{ fatura.ilce }} / {{ fatura.il }} 
                            </span>
                            <span class="text-muted fw-normal d-block"
                              >VD: {{ fatura.vergidairesi }} / {{ fatura.vergino }}  </span
                            >
                          </label>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-wrap
                            p-2
                            py-1
                            bg-light
                            rounded-bottom
                            border
                            mt-n1
                          "
                        >
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="selectFatura = fatura.id"
                              data-bs-toggle="modal" data-bs-target="#faturaModal"
                              ><i
                                class="
                                  ri-pencil-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Düzenle") }}</a
                            >
                          </div>
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="removeFatura(fatura.id)"
                              ><i
                                class="
                                  ri-delete-bin-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Sil") }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                      </div>
                      <!--end row-->
                    </div>
                  </div>
                  <!--end col-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="adresBilgileri" role="tabpanel">
                <div class="d-flex">
                  <div class="p-2 flex-grow-1"><h4>{{ $t("Sevkiyat Adres Bilgileri") }}</h4></div>
                  <div class="p-2">
                    <button
                        type="button"
                        class="btn btn-sm btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#adresAddModal"
                        @click="selectAddAdres = true"
                      >
                        {{ $t("Sevkiyat Adresi Ekle") }}
                      </button>
                  </div>  
                </div>
                <form>
                  <div id="newlink">
                    <div id="1">
                      <div class="row">
                        <div class="row gy-2">
                      <div class="col-lg-4 col-sm-6" v-for="adres in SevkiyatAdresleri" :key="adres.id">
                        <div class="form-check card-radio">
                          <input
                            :id="'adresAddress'+adres.id"
                            name="adresAddress"
                            type="radio"
                            class="form-check-input"
                            checked
                          />
                          <label
                            class="form-check-label"
                            :for="'adresAddress'+adres.id"
                          >
                            <span
                              class="
                                mb-4
                                fw-bold
                                d-block
                                text-muted text-uppercase
                              "
                              >{{ adres.baslik }}</span
                            >

                            <span class="fs-15 mb-2">{{ adres.adres }} {{ adres.ilce }} / {{  adres.il }}</span>
                            <span
                              class="
                                text-muted
                                fw-normal
                                text-wrap
                                mb-1
                                d-block
                              "
                              >{{ adres.adres }} {{ adres.ilce }} / {{  adres.il }}
                            </span>
                            <span class="text-muted fw-normal d-block"
                              >VD: YALOVA / 9060055796  </span
                            >
                          </label>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-wrap
                            p-2
                            py-1
                            bg-light
                            rounded-bottom
                            border
                            mt-n1
                          "
                        >
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="selectAdres = adres.id"
                              data-bs-toggle="modal" data-bs-target="#adressModal"
                              ><i
                                class="
                                  ri-pencil-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Düzenle") }}</a
                            >
                          </div>
                          <div>
                            <a
                              href="#"
                              class="d-block text-body p-1 px-2"
                              @click="removeAdres(adres.id)"
                              ><i
                                class="
                                  ri-delete-bin-fill
                                  text-muted
                                  align-bottom
                                  me-1
                                "
                              ></i>
                              {{ $t("Sil") }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                      </div>
                      <!--end row-->
                    </div>
                  </div>
                  <!--end col-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="privacy" role="tabpanel">
                <div class="mb-4 pb-2">
                  <h5 class="card-title text-decoration-underline mb-3">
                    Security:
                  </h5>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                    <div class="flex-grow-1">
                      <h6 class="fs-15 mb-1">Two-factor Authentication</h6>
                      <p class="text-muted">
                        Two-factor authentication is an enhanced security
                        meansur. Once enabled, you'll be required to give two
                        types of identification when you log into Google
                        Authentication and SMS are Supported.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a href="javascript:void(0);" class="btn btn-sm btn-primary">Enable Two-facor Authentication</a>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                    <div class="flex-grow-1">
                      <h6 class="fs-15 mb-1">Secondary Verification</h6>
                      <p class="text-muted">
                        The first factor is a password and the second commonly
                        includes a text with a code sent to your smartphone, or
                        biometrics using your fingerprint, face, or retina.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a href="javascript:void(0);" class="btn btn-sm btn-primary">Set up secondary method</a>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                    <div class="flex-grow-1">
                      <h6 class="fs-15 mb-1">Backup Codes</h6>
                      <p class="text-muted mb-sm-0">
                        A backup code is automatically generated for you when
                        you turn on two-factor authentication through your iOS
                        or Android Twitter app. You can also generate a backup
                        code on twitter.com.
                      </p>
                    </div>
                    <div class="flex-shrink-0 ms-sm-3">
                      <a href="javascript:void(0);" class="btn btn-sm btn-primary">Generate backup codes</a>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <h5 class="card-title text-decoration-underline mb-3">
                    Application Notifications:
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="d-flex">
                      <div class="flex-grow-1">
                        <label for="directMessage" class="form-check-label fs-15">Direct messages</label>
                        <p class="text-muted">
                          Messages from people you follow
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="directMessage" checked />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label class="form-check-label fs-15" for="desktopNotification">
                          Show desktop notifications
                        </label>
                        <p class="text-muted">
                          Choose the option you want as your default setting.
                          Block a site: Next to "Not allowed to send
                          notifications," click Add.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="desktopNotification"
                            checked />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label class="form-check-label fs-15" for="emailNotification">
                          Show email notifications
                        </label>
                        <p class="text-muted">
                          Under Settings, choose Notifications. Under Select an
                          account, choose the account to enable notifications
                          for.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="emailNotification" />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label class="form-check-label fs-15" for="chatNotification">
                          Show chat notifications
                        </label>
                        <p class="text-muted">
                          To prevent duplicate mobile notifications from the
                          Gmail and Chat apps, in settings, turn off Chat
                          notifications.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="chatNotification" />
                        </div>
                      </div>
                    </li>
                    <li class="d-flex mt-2">
                      <div class="flex-grow-1">
                        <label class="form-check-label fs-15" for="purchaesNotification">
                          Show purchase notifications
                        </label>
                        <p class="text-muted">
                          Get real-time purchase alerts to protect yourself from
                          fraudulent charges.
                        </p>
                      </div>
                      <div class="flex-shrink-0">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" role="switch" id="purchaesNotification" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 class="card-title text-decoration-underline mb-3">
                    Delete This Account:
                  </h5>
                  <p class="text-muted">
                    Go to the Data & Privacy section of your profile Account.
                    Scroll to "Your data & privacy options." Delete your Profile
                    Account. Follow the instructions to delete your account :
                  </p>
                  <div>
                    <input type="password" class="form-control" id="passwordInput" placeholder="Enter your password"
                      value="make@321654987" style="max-width: 265px" />
                  </div>
                  <div class="hstack gap-2 mt-3">
                    <a href="javascript:void(0);" class="btn btn-soft-danger">Close & Delete This Account</a>
                    <a href="javascript:void(0);" class="btn btn-light">Cancel</a>
                  </div>
                </div>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!-- Vertically Centered -->
<!-- Default Modals -->
<div id="adressModal" ref="modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="adressModalLabel">#{{selectAdres}} - {{ $t("Adres Güncelle") }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat" @click="selectAdres = null"> </button>
            </div>
            <div class="modal-body" v-if="selectAdres != null">
              <div class="row gy-3">
                  <div class="col-md-12">
                    <label for="baslik" class="form-label">{{ $t("Başlık") }}</label>
                    <input type="text" class="form-control" id="baslik"
                        :placeholder="t('Başlık')" v-model="selectAdresData.baslik">
                  </div>
                  <div class="col-md-6">
                    <label for="il" class="form-label">{{ $t("İl") }}</label>
                    <Multiselect
                        id="il"
                        v-model="selectAdresData.il"
                        :placeholder="t('İl')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectAdresData.il, label: selectAdresData.il },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="ilce" class="form-label">{{ $t("İlçe") }}</label>
                    <Multiselect
                        id="ilce"
                        v-model="selectAdresData.ilce"
                        :placeholder="t('İlçe')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectAdresData.ilce, label: selectAdresData.ilce },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="bucak" class="form-label">{{ $t("Bucak/Köy") }}</label>
                      <Multiselect
                        id="bucak"
                        v-model="selectAdresData.bucak"
                        :placeholder="t('Bucak/Köy')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectAdresData.bucak, label: selectAdresData.bucak },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="mahalle" class="form-label">{{ $t("Mahalle") }}</label>
                      <Multiselect
                        id="mahalle"
                        v-model="selectAdresData.mahalle"
                        :placeholder="t('Mahalle')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: selectAdresData.mahalle, label: selectAdresData.mahalle },
                        ]"
                      />
                  </div>
                  <div class="col-md-12">
                    <label for="adres" class="form-label"
                      >{{ $t("Adres") }}</label
                    >
                    <textarea
                      class="form-control"
                      id="adres"
                      rows="3"
                      :placeholder="t('Adres')"
                      v-model="selectAdresData.adres"
                    ></textarea>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
                <button ref="adresClose" type="button" class="btn btn-light" data-bs-dismiss="modal" @click="selectAdres = null">{{ $t("Vazgeç") }}</button>
                <button type="button" class="btn btn-primary " @click="updateAdress">{{ $t("Güncelle") }}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<div id="faturaModal" ref="modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="faturaModalLabel">#{{selectFatura}} - {{ $t("Fatura Bilgileri Güncelle") }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat" @click="selectFatura = null"> </button>
            </div>
            <div class="modal-body" v-if="selectFatura != null">
              <div class="row gy-3">
                  <div class="col-md-12">
                    <label for="baslik" class="form-label">{{ $t("Başlık") }}</label>
                    <input type="text" class="form-control" id="baslik"
                        :placeholder="t('Başlık')" v-model="selectFaturaData.baslik">
                  </div>
                  <div class="col-md-12">
                    <label for="unvan" class="form-label">{{ $t("Ünvan") }}</label>
                    <input type="text" class="form-control" id="unvan"
                        :placeholder="t('Ünvan')" v-model="selectFaturaData.unvan">
                  </div>
                  <div class="col-md-6">
                    <label for="vergidairesi" class="form-label">{{ $t("Vergi Dairesi") }}</label>
                    <input type="text" class="form-control" id="vergidairesi"
                        :placeholder="t('Vergi Dairesi')" v-model="selectFaturaData.vergidairesi">
                  </div>
                  <div class="col-md-6">
                    <label for="vergino" class="form-label">{{ $t("Vergi Numarası") }}</label>
                    <input type="number" class="form-control" id="vergino"
                        :placeholder="t('Vergi Numarası')" v-model="selectFaturaData.vergino">
                  </div>
                  <div class="col-md-6">
                    <label for="il" class="form-label">{{ $t("İl") }}</label>
                    <Multiselect
                        id="il"
                        v-model="selectFaturaData.il"
                        :placeholder="t('İl')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectFaturaData.il, label: selectFaturaData.il },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="ilce" class="form-label">{{ $t("İlçe") }}</label>
                    <Multiselect
                        id="ilce"
                        v-model="selectFaturaData.ilce"
                        :placeholder="t('İlçe')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectFaturaData.ilce, label: selectFaturaData.ilce },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="bucak" class="form-label">{{ $t("Bucak/Köy") }}</label>
                      <Multiselect
                        id="bucak"
                        v-model="selectFaturaData.bucak"
                        :placeholder="t('Bucak/Köy')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: selectFaturaData.bucak, label: selectFaturaData.bucak },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="mahalle" class="form-label">{{ $t("Mahalle") }}</label>
                      <Multiselect
                        id="mahalle"
                        v-model="selectFaturaData.mahalle"
                        :placeholder="t('Mahalle')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: selectFaturaData.mahalle, label: selectFaturaData.mahalle },
                        ]"
                      />
                  </div>
                  <div class="col-md-12">
                    <label for="adres" class="form-label"
                      >{{ $t("Adres") }}</label
                    >
                    <textarea
                      class="form-control"
                      id="adres"
                      rows="3"
                      :placeholder="t('Adres')"
                      v-model="selectFaturaData.adres"
                    ></textarea>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
                <button ref="faturaClose" type="button" class="btn btn-light" data-bs-dismiss="modal" @click="selectFatura = null">{{ $t("Vazgeç") }}</button>
                <button type="button" class="btn btn-primary " @click="updateFatura">{{ $t("Güncelle") }}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
<div id="faturaAddModal" ref="modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="faturaAddModalLabel">#{{ $t("Fatura Bilgisi Ekle") }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat" @click="selectAddFatura = null"> </button>
            </div>
            <div class="modal-body" v-if="selectAddFatura != null">
              <div class="row gy-3">
                  <div class="col-md-12">
                    <label for="baslik" class="form-label">{{ $t("Başlık") }}</label>
                    <input type="text" class="form-control" id="baslik"
                        :placeholder="t('Başlık')" v-model="selectAddFaturaData.baslik">
                  </div>
                  <div class="col-md-12">
                    <label for="unvan" class="form-label">{{ $t("Ünvan") }}</label>
                    <input type="text" class="form-control" id="unvan"
                        :placeholder="t('Ünvan')" v-model="selectAddFaturaData.unvan">
                  </div>
                  <div class="col-md-6">
                    <label for="vergidairesi" class="form-label">{{ $t("Vergi Dairesi") }}</label>
                    <input type="text" class="form-control" id="vergidairesi"
                        :placeholder="t('Vergi Dairesi')" v-model="selectAddFaturaData.vergidairesi">
                  </div>
                  <div class="col-md-6">
                    <label for="vergino" class="form-label">{{ $t("Vergi Numarası") }}</label>
                    <input type="number" class="form-control" id="vergino"
                        :placeholder="t('Vergi Numarası')" v-model="selectAddFaturaData.vergino">
                  </div>
                  <div class="col-md-6">
                    <label for="il" class="form-label">{{ $t("İl") }}</label>
                    <Multiselect
                        id="il"
                        v-model="selectAddFaturaData.il"
                        :placeholder="t('İl')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: 'YALOVA', label: 'YALOVA' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="ilce" class="form-label">{{ $t("İlçe") }}</label>
                    <Multiselect
                        id="ilce"
                        v-model="selectAddFaturaData.ilce"
                        :placeholder="t('İlçe')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: 'ÇİFTLİKKÖY', label: 'ÇİFTLİKKÖY' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="bucak" class="form-label">{{ $t("Bucak/Köy") }}</label>
                      <Multiselect
                        id="bucak"
                        v-model="selectAddFaturaData.bucak"
                        :placeholder="t('Bucak/Köy')"
                        :close-on-select="false"
                        :searchable="true"
                        :options="[
                          { value: 'MERKEZ', label: 'MERKEZ' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="mahalle" class="form-label">{{ $t("Mahalle") }}</label>
                      <Multiselect
                        id="mahalle"
                        v-model="selectAddFaturaData.mahalle"
                        :placeholder="t('Mahalle')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: 'ÇİFTLİK', label: 'ÇİFTLİK' },
                        ]"
                      />
                  </div>
                  <div class="col-md-12">
                    <label for="adres" class="form-label"
                      >{{ $t("Adres") }}</label
                    >
                    <textarea
                      class="form-control"
                      id="adres"
                      rows="3"
                      :placeholder="t('Adres')"
                      v-model="selectAddFaturaData.adres"
                    ></textarea>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
                <button ref="faturaAddClose" type="button" class="btn btn-light" data-bs-dismiss="modal" @click="selectAddFatura = null">{{ $t("Vazgeç") }}</button>
                <button type="button" class="btn btn-primary " @click="addFatura">{{ $t("Kaydet") }}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
<div id="adresAddModal" ref="modal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="adresAddModalLabel">#{{ $t("Sevkiyat Adresi Ekle") }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat" @click="selectAddAdres = null"> </button>
            </div>
            <div class="modal-body" v-if="selectAddAdres != null">
              <div class="row gy-3">
                  <div class="col-md-12">
                    <label for="baslik" class="form-label">{{ $t("Başlık") }}</label>
                    <input type="text" class="form-control" id="baslik"
                        :placeholder="t('Başlık')" v-model="selectAddAdresData.baslik">
                  </div>
                  <div class="col-md-6">
                    <label for="il" class="form-label">{{ $t("İl") }}</label>
                    <Multiselect
                        id="il"
                        v-model="selectAddAdresData.il"
                        :placeholder="t('İl')"
                        :close-on-select="true"
                        :clearOnSelect="true"
                        :searchable="true"
                        :options="[
                          { value: 'YALOVA', label: 'YALOVA' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="ilce" class="form-label">{{ $t("İlçe") }}</label>
                    <Multiselect
                        id="ilce"
                        v-model="selectAddAdresData.ilce"
                        :placeholder="t('İlçe')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: 'ÇİFTLİKKÖY', label: 'ÇİFTLİKKÖY' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="bucak" class="form-label">{{ $t("Bucak/Köy") }}</label>
                      <Multiselect
                        id="bucak"
                        v-model="selectAddAdresData.bucak"
                        :placeholder="t('Bucak/Köy')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: 'MERKEZ', label: 'MERKEZ' },
                        ]"
                      />
                  </div>
                  <div class="col-md-6">
                    <label for="mahalle" class="form-label">{{ $t("Mahalle") }}</label>
                      <Multiselect
                        id="mahalle"
                        v-model="selectAddAdresData.mahalle"
                        :placeholder="t('Mahallle')"
                        :close-on-select="true"
                        :searchable="true"
                        :options="[
                          { value: 'ÇİFTLİK', label: 'ÇİFTLİK' },
                        ]"
                      />
                  </div>
                  <div class="col-md-12">
                    <label for="adres" class="form-label"
                      >{{ $t("Adres") }}</label
                    >
                    <textarea
                      class="form-control"
                      id="adres"
                      rows="3"
                      :placeholder="t('Adres')"
                      v-model="selectAddAdresData.adres"
                    ></textarea>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
                <button ref="adresAddClose" type="button" class="btn btn-light" data-bs-dismiss="modal" @click="selectAddAdres = null">{{ $t("Vazgeç") }}</button>
                <button type="button" class="btn btn-primary " @click="addAdres">{{ $t("Kaydet") }}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
    <!--end row-->
  </Layout>
</template>